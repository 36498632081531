export class StringToIntValueConverter {
    fromView(value) {
        if (value == null || value == 0) {
            return 0;
        }

        if (value != null) {
            var intToReturn = parseInt(value, 10);

            if (!Number.isNaN(intToReturn) && typeof intToReturn == "number") {
                return intToReturn;
            } else {
                return 0;
            }
        }
    }

    toView(value) {
        if (value == 0) {
            return null;
        } else {
            return value;
        }
    }
}
