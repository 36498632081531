import numeral from 'numeral';

export class NumberFormatValueConverter {
	toView(value, format = '0.00') {
		return numeral(value).format(format);
	}

	fromView(stringValue, format = '0.00') {
		if (stringValue == null || stringValue === "")
			return null;

		if (format == null || format == "")
			format = '$0,0.00';

		stringValue = stringValue.trim();

		if (format.indexOf("%") != -1 && stringValue.indexOf("%") == -1)
			stringValue += "%";

		var value = numeral(stringValue).value();
		return value;
	}
}