import { customElement, autoinject, bindable } from "aurelia-framework";
import { IClient } from "interfaces/entity-interfaces";
import { AuthenticationService } from "services/authentication-service";

@autoinject()
@customElement("sidebar-element")
export class SideBarElement {
    private _authenticationService: AuthenticationService;
    @bindable sidebarOpen: boolean;

    client: IClient;

    constructor(authenticationService: AuthenticationService) {
        this._authenticationService = authenticationService;
    }

    attached() {
        this.client = this._authenticationService.client;
        // console.log("Client: ", this.client);
    }
}
